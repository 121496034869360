import { Link } from 'gatsby'
import React from 'react'
import PuxSocials from 'Shared/components/PuxSocials/PuxSocials'
import { t } from 'ttag'

const rootClass = `footer`

const Footer: React.FunctionComponent = () => {
  const year = `${new Date().getFullYear()}`

  return (
    <footer className={rootClass}>
      <div className='pux-container'>
        <div className={`${rootClass}-foot`}>
          <div className={`${rootClass}-social`}>
            <p className={`${rootClass}-social-text`}>
              {t`Redmine.Footer.SocialMedia`}
            </p>
            <PuxSocials
              urlFacebook='https://www.facebook.com/easysoftwarecom/'
              urlLinkedin='https://www.linkedin.com/company/easy-software-ltd./'
            />
          </div>
          <div className={`${rootClass}-copyright`}>
            {t`Redmine.Footer.Copyright`.replace(`%currentYear%`, year)}
            <span className={`${rootClass}-sitemap`}>
              <Link to={`/sitemap.xml`}>{t`Redmine.Footer.SiteMap`}, XML</Link>
            </span>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
