import { AnchorItemType } from 'EasySoftwareGatsby/components/core/AnchorsNav'
import { Link } from 'gatsby'
import React from 'react'
import { Sticky } from 'react-sticky'
import PuxButton, {
  PuxButtonColor,
  PuxButtonStyle,
} from 'Shared/components/PuxButton/PuxButton'
import PuxLanguageSelector from 'Shared/components/PuxLanguageSelector/PuxLanguageSelector'
import { LinkType } from 'Shared/types/Link'
import { getFromConfig, getHrefLang } from 'Shared/utils/localeURL'

import { useHeaderData } from '../../queries/_use-header-data.js'
import { ReactComponent as Logo } from '../../svg/logo-software.svg'
import IconClose from '../../svg/mobile-menu-off.svg'
import IconBurger from '../../svg/mobile-menu-on.svg'
import AnchorsNav from './AnchorsNav'
import HeaderNav, { HeaderData } from './HeaderNav'

const rootClass = `header`
const localizedHomepageUrl = `/${getFromConfig(
  process.env.LOCALE ?? `en`,
  `urlPrefix`,
  `EasySoftwareGatsby`
)}`

interface HeaderPropsType {
  customHeaderClass?: string
  customHeaderFnc: (duplicatedNav: boolean) => void
  style?: string
  cta?: LinkType
  anchors?: AnchorItemType[]
}

const Header: React.FunctionComponent<HeaderPropsType> = (props) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [isLanguageDialogOpen, setLanguageDialogOpen] = React.useState(false)
  const headerData: HeaderData = useHeaderData()

  return (
    <div className={`${props.customHeaderClass ?? ``}`}>
      <Sticky disableCompensation>
        {({
          distanceFromTop,
        }: {
          style: React.CSSProperties
          distanceFromTop: number
        }) => (
          <header
            className={`${isOpen ? `open ` : ``}${distanceFromTop < 0 ? `scrolled` : ``
              }`}
          >
            <div className='pux-container'>
              <div
                className={`${rootClass} ${props.style === `landing` ? rootClass + `-landing` : ``
                  }`}
              >
                <div className={`${rootClass}-logo`}>
                  <Link to={localizedHomepageUrl}>
                    <Logo />
                  </Link>
                </div>

                {/* {props.style === `landing` && (
                  <div className={`${rootClass}-home`}>
                    <PuxButton
                      buttonType={PuxButtonStyle.LINK}
                      buttonColor={PuxButtonColor.PRIMARY}
                      buttonLink={{
                        url: [`/`],
                        internal: [true],
                        text: [`Easy Redmine`],
                      }}
                    />
                  </div>
                )} */}

                <div
                  className={`${rootClass}-burger`}
                  onClick={() => setIsOpen(!isOpen)}
                >
                  <span className='open'>
                    <img src={IconBurger} width={20} height={20} />
                  </span>
                  <span className='close'>
                    <img src={IconClose} width={20} height={20} />
                  </span>
                </div>
                <HeaderNav
                  headerData={headerData}
                  setLanguageDialogOpen={setLanguageDialogOpen}
                />
                {props.anchors && <AnchorsNav items={props.anchors} />}
                <div className={`${rootClass}-language`}>
                  <PuxLanguageSelector
                    isOpen={isLanguageDialogOpen}
                    setOpen={setLanguageDialogOpen}
                  />
                </div>
              </div>
            </div>
          </header>
        )}
      </Sticky>
      <div className={`after-header`}></div>
    </div>
  )
}

export default Header
