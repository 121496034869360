import { useLocation } from '@reach/router'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import React, { FunctionComponent } from 'react'

export interface AnchorItemType {
  displayText: string
  anchorItemTarget: string
}

export interface AnchorsNavProps {
  items: AnchorItemType[]
}

const AnchorsNav: FunctionComponent<AnchorsNavProps> = (props) => {
  return (
    <nav className={`header-menu header-menu-anchors`}>
      <ul>
        {props.items.map((item, idx) => {
          const location = useLocation()
          const pathname = location.pathname

          return (
            <li key={idx}>
              <AnchorLink to={pathname + `#` + item.anchorItemTarget}>
                {item.displayText}
              </AnchorLink>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

export default AnchorsNav
