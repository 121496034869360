/* eslint-disable prettier/prettier */
import { useLocation } from '@reach/router'
import { Link } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import { decode } from 'html-entities'
import React, { Dispatch, FunctionComponent, SetStateAction } from 'react'
import { getHrefLang, getLocalizedUrl } from 'Shared/utils/localeURL'

export interface MenuItem {
  linkMenuItem: {
    name: string
    url: string
  }
}
export interface HeaderData {
  menuItems: [MenuItem]
}
interface HeaderNavProps {
  headerData: HeaderData
  setLanguageDialogOpen: Dispatch<SetStateAction<boolean>>
}
export interface renderData {
  url: string
  name: string
}

const renderTopMenuItem = (item: MenuItem, index: number, setLanguageDialogOpen: any) => {
  const [hovered, setHovered] = React.useState(false)
  const location = useLocation()
  const pathname = location.pathname

  return (
    <li
      className={`${hovered ? ` is-hovered` : ``}`}
      key={index}
    >
      {item.linkMenuItem.url.includes(`#`) && (
        <AnchorLink
          to={`${pathname}${item.linkMenuItem.url}`}
        >
          {decode(item.linkMenuItem.name)}
        </AnchorLink>
      )}
      {!item.linkMenuItem.url.includes(`#`) && (
        <Link
          activeClassName='is-active'
          partiallyActive={true}
          to={`${getLocalizedUrl(item.linkMenuItem.url)}`}
          onMouseEnter={() => {
            setLanguageDialogOpen(false)
            setHovered(true)
          }}
          onMouseLeave={() => setHovered(false)}
        >
          {decode(item.linkMenuItem.name)}
        </Link>
      )}
    </li>
  )
}

const HeaderNav: FunctionComponent<HeaderNavProps> = (props) => {
  return (
    <nav className={`header-menu header-menu-default`}>
      <ul>
        {props.headerData.menuItems.map((item, index) => renderTopMenuItem(item, index, props.setLanguageDialogOpen))}
      </ul>
    </nav>
  )
}

export default HeaderNav
